<template>
  <div class="bg-gray-main py-16 mb-16">
    <div class="container">
      <h1 class="text-3xl font-bold">View Salaries</h1>
    </div>
    <div class="mt-12">
      <div class="container">
        <div class="hidden lg:flex flex-wrap justify-between">
          <div class="flex space-x-6">
            <div class="w-full">
              <SelectOptGroup
                v-model="salariesFilterOptions.jobFamily"
                :options="jobFamilesForSelectOpt()"
              />
            </div>
            <div class="w-full">
              <SearchAndFilter
                @input="handleFilterOptChange"
                v-model="salariesFilterOptions"
              />
            </div>
          </div>
          <div>
            <Button
              @click="$router.push({ name: 'Add Salary' })"
              custom_class="text-sm"
              text="Add Salary"
              :icon="['fas', 'plus']"
            />
          </div>
        </div>
        <div class="flex flex-wrap lg:hidden justify-between">
          <div class="flex space-x-6">
            <div>
              <SelectOptGroup :options="jobFamilesForSelectOpt()"  v-model="salariesFilterOptions.jobFamily" />
            </div>

            <Button
              custom_class="text-sm"
              text="Add Salary"
              :icon="['fas', 'plus']"
              @click="$router.push({ name: 'Add Salary' })"
            />
          </div>
          <div class="w-full mt-2">
            <SearchAndFilter
              @input="handleFilterOptChange"
              v-model="salariesFilterOptions"
            />
          </div>
        </div>
      </div>

      <div class="mt-12">
        <div class="container">
          <SubmitSalaryMessage
            :salary_submitted="salarySubmitted"
            @skip="skip"
            @salary-submitted="salaryIsSubmitted"
          />
          <div v-if="salarySubmitted">
            <SalariesTable
              id="salaries-table"
              ref="salariesTable"
              :per_page="perPage"
              @per-page-changed="perPageChange"
              :columns="salaryColumns"
              :endpoint="salariesApiEndpoint"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { getJobFamilies } from "@/helpers/enums";
import Button from "../components/core/Button.vue";
import SalariesTable from "../components/derived/SalariesTable";
import SelectOptGroup from "../components/core/SelectOptGroup";
import SubmitSalaryMessage from "../components/derived/SubmitSalaryMessage.vue";
import SearchAndFilter from "../components/derived/SearchAndFilter.vue";

export default {
  name: "Salaries",
  data() {
    return {
      salarySubmitted: false,
      perPage: 5,
      salaryColumns: [
        {
          name: "company-slot",
          title:
            "<span class='text-sm md:text-base'>Company</span> <br> <small class='text-black-light font-light'>Location | Date</small>",
          sortField: "companyName",
        },
        {
          name: "level-slot",
          title:
            "<span class='text-sm md:text-base'>Job</small> <br> <small class='text-black-light font-light'>Job Family</small>",
          dataClass: "v-align-middle",
        },

        {
          name: "experience-slot",
          title:
            "<span class='text-sm md:text-base'>Years of Experience</span> <br> <small class='text-black-light font-light'>At Company / All Time</small>",
          sortField: "totalExp",
          dataClass: "experience-data hidden lg:block",
        },
        {
          name: "totalsalary-slot",
          title:
            "<span class='text-sm md:text-base'>Total Salary</span> <br> <small class='text-black-light font-light'>Base | Stock | Bonus</small>",
          sortField: "totalYearlyComp",
        },
      ],
      salariesApiEndpoint: "/api/user/salaries",

      salariesFilterOptions: {
        jobFamily: null,
        searchKeyword: null,
      },
    };
  },
  components: {
    SubmitSalaryMessage,
    Button,
    SalariesTable,
    SearchAndFilter,
    SelectOptGroup,
  },
  mounted() {
    // On search recieved
    Vue.set(
      this.salariesFilterOptions,
      "searchTerm",
      this.$route.query.searchKeyword
    );

    // Check if salary is already submitted
    this.salarySubmitted = localStorage.getItem("salarySubmitted")
      ? true
      : false;
  },
  methods: {
    handleFilterOptChange(values) {
      console.log(values);

      Object.keys(values).forEach((key) =>
        Vue.set(this.salariesFilterOptions, key, values[key])
      );
    },

    salaryIsSubmitted() {
      localStorage.setItem("salarySubmitted", true);
      this.salarySubmitted = true;
    },
    skip() {
      this.salarySubmitted = true;
    },
    perPageChange(perPageValue) {
      this.perPage = parseInt(perPageValue);
    },
    jobFamilesForSelectOpt() {
      return getJobFamilies();
    },
  },
  watch: {
    salariesFilterOptions: {
      handler: function (values) {
        let endpoint = "/api/user/salaries?currency=ngn&";
        let filters = [];
        // build new api endpoint

        if (values.searchTerm) {
          endpoint += `searchColumn=companyName,jobTitle&searchKeyword=${values.searchTerm}&searchType=contains`;
        }
        if (values.gender) {
          filters.push({
            name: "gender",
            comparator: "eq",
            values: values.gender,
          });
        }
        if (values.jobFamily) {
          filters.push({
            name: "jobFamily",
            comparator: "eq",
            values: values.jobFamily,
          });
        }
        if (values.timeRange && values.timeRange != 'null') {
          filters.push({
            name: "createdAt",
            comparator: "between",
            values: values.timeRange.split('|'),
          });
        }
        if (values.yoe && values.yoe != 'null') {
          filters.push({
            name: "totalExp",
            comparator: "between",
            values: JSON.parse(values.yoe),
          });
        }
        endpoint += `&customField={"filters": ${JSON.stringify(filters)}}`;
        this.salariesApiEndpoint = endpoint;
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
</style>
