<template>
  <div>
    <TextBox
      :id="'search-large' + Date.now()"
      :value="filterOptions.searchTerm"
      @input="updateValue()"
      type="text"
      :icon="['fas', 'filter']"
      :isIconClickable="true"
      placeholder="Search by Company, title or location"
      @icon-clicked="toggleFilter"
      v-model="filterOptions.searchTerm"
      input_wrapper_class="mw-300"
    />
    <transition name="fade">
      <template v-if="showFilter">
        <div class="relative z-50" id="filterOptionsContainer">
          <div class="absolute bg-white rounded filter-div">
            <div>
              <div class="text-center px-2 py-2">
                <small class="text-gray-text">FILTER BY YOE</small>
              </div>
              <hr class="border-dashed" />
              <div class="px-2 py-2">
                <div
                  class="flex items-center"
                  v-for="(value, key) in levels()"
                  :key="key"
                >
                  <input
                    :id="'radio-' + key"
                    type="radio"
                    class="form-radio"
                    name="level"
                    :value="key"
                    @input="updateValue()"
                    v-model="filterOptions.yoe"
                  />
                  <label class="ml-2" :for="'radio-' + key">
                    <small>
                      {{ value }}
                    </small>
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div>
                <div class="text-center px-2 py-2">
                  <small class="text-gray-text">FILTER BY GENDER</small>
                </div>
                <hr class="border-dashed" />
                <div class="px-2 py-2">
                  <div
                    class="flex items-center"
                    v-for="(value, key) in genders()"
                    :key="key"
                  >
                    <input
                      :id="'radio-genders-' + key"
                      type="radio"
                      class="form-radio"
                      :value="key"
                      name="gender"
                      @input="updateValue()"
                      v-model="filterOptions.gender"
                    />
                    <label class="ml-2" :for="'radio-genders-' + key">
                      <small>
                        {{ value }}
                      </small>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div>
                <div class="text-center px-2 py-2">
                  <small class="text-gray-text">FILTER BY TIME RANGE</small>
                </div>
                <hr class="border-dashed" />
                <div class="px-2 py-2">
                  <div
                    class="flex items-center"
                    v-for="(value, key) in filterTimeRange()"
                    :key="key"
                  >
                    <input
                      :id="'radio-time-range-' + key"
                      type="radio"
                      class="form-radio"
                      :value="key"
                      name="timeRange"
                      @input="updateValue()"
                      v-model="filterOptions.timeRange"
                    />
                    <label class="ml-2" :for="'radio-time-range-' + key">
                      <small>
                        {{ value }}
                      </small>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import TextBox from "../core/TextBox";
import { levels, genders, filterTimeRange } from "@/helpers/enums";

export default {
  name: "SearchAndFilter",
  components: {
    TextBox,
  },
  props: {
    value: null,
  },
  data() {
    return {
      showFilter: false,

      filterOptions: {
        searchTerm: null,
        yoe: null,
        gender: null,
        timeRange: null,
      },
    };
  },

  created() {
    if (this.value) this.filterOptions = this.value;
  },

  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    updateValue() {
      this.$emit("input", this.filterOptions);
    },

    levels(key, valuesOnly, namesOnly) {
      return levels(key, valuesOnly, namesOnly);
    },

    genders(key, valuesOnly, namesOnly) {
      return genders(key, valuesOnly, namesOnly);
    },
    filterTimeRange() {
      return filterTimeRange();
    },
  },
};
</script>

<style>
.filter-div {
  width: 200px;
}

hr {
  @apply border-gray-main;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>