<template>
  <div class="mb-4" :class="wrapper_class">
    <label v-if="label" :for="id"
      >{{ label }} <span v-if="isRequired" class="text-red-500">*</span></label
    >
    <validation-provider :rules="rules" v-slot="{ errors }">
      <div
        class="
          flex
          input-wrapper
          items-center
          border border-gray-border
          bg-white
          w-full
          rounded
        "
        :class="input_wrapper_class"
      >
        <div
          class="icon-wrapper"
          :class="{ 'cursor-pointer': isIconClickable }"
          v-if="icon"
          @click="$emit('icon-clicked')"
        >
          <font-awesome-icon :icon="icon" :style="{ color: '#fff' }" />
        </div>
        <input
          :type="type"
          ref="input"
          class="pl-3 w-full focus:outline-none"
          :id="id"
          :class="custom_class"
          :placeholder="placeholder"
          :value="value"
          @input="updateValue"
          @change="handleChange"
          :disabled="disabled"
        />
      </div>
      <div class="error pt-1" v-if="errors.length && !hide_error">
        <small
          class="text-red-500 text-sm"
          v-for="(error, index) in errors"
          :key="index"
          >{{ error }}</small
        >
      </div>
      <small v-if="subtitle" class="text-black-light">{{ subtitle }}</small>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "TextBox",
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    rules: String,
    icon: Array,
    isIconClickable: {
      type: Boolean,
      default: false,
    },
    maxval: {
      type: Number,
    },
    value: {
      type: String,
    },
    subtitle: String,
    derived: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    custom_class: {
      type: String,
    },
    wrapper_class: {
      type: String,
    },
    input_wrapper_class: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hide_error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRequired() {
      // If no rules set
      if(!this.rules) return false;
      return this.rules.includes("required");
    },
  },
  emits: ["input", "change"],
  methods: {
    updateValue() {
      this.$emit("input", this.$refs.input.value);
    },
    handleChange() {
      this.$emit("change", this.$refs.input.value);
    },
  },
};
</script>

<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="password"] {
  letter-spacing: 1px;
}

.input-wrapper {
  height: 42px;
  margin-top: 6px;
}

.icon-wrapper {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply ml-1;
  height: 90%;
  width: 42px;
  padding-right: 15px;
  padding-left: 15px;
  @apply rounded-md;
  @apply bg-purple;
}

.mw-300 {
  min-width: 300px;
}
</style>