<template>
  <div class="mt-12">
    <div class="container">
      <div v-if="!salary_submitted" class="hidden-table" >

        <div class="text-center flex-block flex-col justify-center">
          <div class="flex justify-center mb-4">
            <img src="@/assets/money-icon.png" />
          </div>
          <h2 class="text-xl font-bold">Submit your salary to view!</h2>
          <div class="my-6 w-4/6 mx-auto">
            <p>
              You can always skip this step, but not only does it help the
              entire community - it also helps change the culture around the
              salary transparency.
            </p>
          </div>
          <div class="flex justify-center space-x-4">
            <Button
              text="Add Salary"
              custom_class="text-sm"
              :icon="['fas', 'plus']"
              @click="$router.push({name:'Add Salary'})"
            />
            <Button
              text="Added Already"
              variant="secondary"
              @click="$emit('salary-submitted')"
              :icon="['fas', 'check-double']"
              custom_class="text-sm"
            />
          </div>
          <div
            class="mt-4 flex space-x-2 items-center justify-center"
            @click="$emit('skip')"
          >
            <a href="#"><small>Skip for Now</small></a>
            <font-awesome-icon
              :style="{ color: '#929292', 'font-size': '12px' }"
              :icon="['fas', 'arrow-right']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/core/Button";

export default {
  name: "SubmitSalaryMessage",
  props: {
    salary_submitted: {
      type: Boolean,
    },
  },
  emits: ["skip", "salary-submitted"],
  components: { Button },
};
</script>

<style scoped lang="scss">
.hidden-table {
  @apply w-full;
  height: 373px;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply relative;
  background-image: url('../../assets/hidden-table.png');
  background-position: center;
  
}

small {
  color: #929292;
}
</style>